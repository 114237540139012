//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import { v4 as uuidv4 } from 'uuid';
import PageHeader from '@/components/tools/PageHeaderCustom.vue';
import UploadPhoto from '@/components/spec/UploadPhoto.vue';

export default {
  components: { PageHeader, UploadPhoto },

  data() {
    return {
      form: this.$form.createForm(this),
      loading: false,
      timer: undefined,
      thumbnail: {
        url: '',
        alt: ''
      },
      dictionary: []
    };
  },

  computed: {
    ...mapGetters('user', ['userInfo']),
    ...mapGetters('project', ['projectById']),
    ...mapGetters('dictionary', ['dictionaryTree']),
    companyId() {
      return this.userInfo.company_id;
    },
    formItemLayout() {
      return {
        labelCol: { xs: { span: 8 }, sm: { span: 3 }, lg: { span: 3 } },
        wrapperCol: { xs: { span: 16 }, sm: { span: 18 }, lg: { span: 18 } }
      };
    },
    formTailLayout() {
      return {
        labelCol: { xs: { span: 8 }, sm: { span: 3 }, lg: { span: 3 } },
        wrapperCol: {
          xs: { span: 16, offset: 8 },
          sm: { span: 20, offset: 3 },
          lg: { span: 10, offset: 3 }
        }
      };
    },
    pathToPages() {
      return `/projects/${this.project.id}/pages`;
    },
    pathToProject() {
      return `${window.location.origin}/projects/${this.project.id}`;
    },
    pathToHomePage() {
      return this.project.sub_domain + this.baseSubDomain;
    },
    formItemOptions() {
      return {
        name: {
          rules: [
            { required: true, message: this.$t('Please input your project name') },
            { max: 50, message: this.$t('Project name max length 50') }
          ],
          initialValue: this.project.name
        },
        description: { initialValue: this.project.description },
        sub_domain: {
          initialValue: this.project.sub_domain,
          rules: [
            { required: true, message: this.$t('Please input your sub domain') },
            {
              validator: this.validateExistSubDomain
            }
          ]
        }
      };
    },
    hpBaseURL() {
      return process.env.VUE_APP_PUBLISHED_CODE_BASE_URL;
    },
    baseSubDomain() {
      return process.env.VUE_APP_UNIVOICE_SUBDOMAIN;
    },
    slug() {
      return this.project.slug || this.project.id;
    },
    folderNameS3() {
      return `${process.env.VUE_APP_S3_PROJECT_THUMBNAIL}/${this.project.id}/${uuidv4()}_${this.project.name}`;
    },
    project() {
      return this.projectById(this.$route.params.projectId);
    },
    dictionaryTreeData() {
      if (this.dictionary.length < 5) {
        return this.dictionaryTree.map(lev1 => ({ ...lev1, title: this.$t(lev1.title) }))
      }
      const newDictionaryTree = this.dictionaryTree.map(lev1 => {
        const children = lev1.children.map(dic => ({
          ...dic,
          disabled: !this.dictionary.includes(dic.value)
        }))
        return {
          ...lev1,
          title: this.$t(lev1.title),
          children
        }
      })
      return newDictionaryTree
    },
  },
  methods: {
    handleSubmitForm() {
      this.loading = true
      this.form.validateFields(async (err, values) => {
        if (err) {
          this.loading = false;
          return;
        }

        const { name, description, sub_domain } = values;
        const input = {
          name,
          description,
          thumbnail: this.thumbnail,
          sub_domain,
          is_public: this.project.is_public,
          includes: ['page'],
          dictionary: this.dictionary?.sort() || []
        };
        this.updateProjectBasicInfo(input);
      });
    },
    async updateProjectBasicInfo(payload) {
      try {
        await this.$s.api.project.editProjectById(this.companyId, this.project.id, payload);
        this.$notification.success({ message: this.$t('This project has been updated') });
        this.$router.push('/projects');
      } catch (error) {
        this.$notification.error({
          message: `${this.$t('This project update failed')}: ${this.$t(error.message)}`
        });
      }
      this.loading = false;
    },
    handleCopyLink(link) {
      this.$copyText(link)
        .then(() => {
          this.$message.success(this.$t('Copy completed'));
        })
        .catch(err => {
          this.$message.error(this.$t('Copy failed'));
        });
    },
    validateExistSubDomain(rule, value, callback) {
      clearTimeout(this.timer);
      this.timer = setTimeout(async () => {
        try {
          if (!value) return callback();
          value = value
            .trim()
            .replace(/\s+/g, '-')
            .replace(/[&\/\\#,+()$~%.'":*?<>_[\]{}]/g, '')
            .toLowerCase();
          this.form.setFieldsValue({ sub_domain: value });
          const isExist = await this.$s.api.project.checkExistSubDomain(`${value}`, this.project.id);
          if (isExist) {
            callback(this.$tc('This sub domain is already exist!'));
          } else {
            callback();
          }
        } catch (error) {
          callback(this.$t(error.message));
        }
        callback();
      }, 1000);
    },
    handleOpenDictionary(dicName) {
      this.$router.push(`/settings/speech-setting?dicName=${dicName}`);
    },
  },
  mounted() {
    // Handle event TreeSelect listener
    const treeSelectElement = this.$refs?.treeSelectRef?.$el;
    treeSelectElement?.addEventListener('click', (event) => {
      if (event?.target?.className === 'ant-select-selection__choice__content') {
        const dicName = event?.target?.textContent
        this.handleOpenDictionary(dicName)
      }
    });
  },
  beforeDestroy() {
    // Handle remove event TreeSelect listener
    const treeSelectElement = this.$refs?.treeSelectRef?.$el;
    treeSelectElement?.removeEventListener('click', null);
  },
  watch: {
    project: {
      handler() {
        this.thumbnail = {
          url: this.project.thumbnail?.url,
          alt: this.project.thumbnail?.alt
        };
        this.dictionary = this.project?.dictionary || []
      },
      immediate: true
    }
  }
  };
